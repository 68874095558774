import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  Grid,
  Box,
} from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import appleLogo from './assets/apple_logo.png';
import googleLogo from './assets/google_play_logo.png';
import axios from 'axios';

function LoginPage({ darkMode, toggleDarkMode }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const API_URL = 'http://0.0.0.0:3839';
  // title
  document.title = 'ShinVPN.Net - Login';
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/login`, {
        username,
        password,
      });
      alert(response.data.message);
    } catch (error) {
      alert(error.response?.data?.detail || 'An error occurred');
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            ShinVPN.Net
          </Typography>
          <IconButton color="inherit" onClick={toggleDarkMode}>
            {darkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Box mt={8}>
          <Paper elevation={3} style={{ padding: '2em' }}>
            <Typography variant="h5" align="center" gutterBottom>
              Login
            </Typography>
            <form onSubmit={handleLogin}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                required
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                required
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Box mt={2}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Login
                </Button>
              </Box>
            </form>
            <Box mt={2}>
              <Button variant="outlined" color="primary" fullWidth>
                Register
              </Button>
            </Box>
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: '1em' }}>
              <Grid item>
                <img src={appleLogo} alt="Apple App Store" style={{ height: '40px' }} />
              </Grid>
              <Grid item>
                <img src={googleLogo} alt="Google Play Store" style={{ height: '40px' }} />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default LoginPage;
